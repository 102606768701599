import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_field = _resolveComponent("van-field")!
  const _component_van_picker = _resolveComponent("van-picker")!
  const _component_van_popup = _resolveComponent("van-popup")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_van_field, {
      readonly: "",
      clickable: "",
      name: _ctx.name,
      label: _ctx.label,
      placeholder: _ctx.placeholder,
      rules: _ctx.rules,
      modelValue: _ctx.showLabel,
      required: _ctx.required,
      size: _ctx.size,
      "right-icon": "arrow-down",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPicker = true))
    }, null, 8, ["name", "label", "placeholder", "rules", "modelValue", "required", "size"]),
    _createVNode(_component_van_popup, {
      show: _ctx.showPicker,
      "onUpdate:show": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showPicker) = $event)),
      position: "bottom"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_van_picker, {
          columns: _ctx.options,
          onConfirm: _ctx.onConfirm,
          onCancel: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPicker = false))
        }, null, 8, ["columns", "onConfirm"])
      ]),
      _: 1
    }, 8, ["show"])
  ], 64))
}