
import { computed, defineComponent, PropType, ref } from 'vue';
import { useVModel } from '@vueuse/core';

interface IOption {
  text: string;
  value: string;
}

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number],
      require: true,
    },
    options: {
      type: Array as PropType<IOption[]>,
    },
    name: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'large',
    },
    rules: {
      type: Array,
    },
    required: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const selected = useVModel(props, 'modelValue', emit);
    const showPicker = ref(false);
    const showLabel = computed(() => props?.options?.find((item) => item.value === selected.value)?.text);

    function onConfirm({ value }: IOption) {
      selected.value = value;
      showPicker.value = false;
    }

    return {
      selected,
      showPicker,
      showLabel,

      onConfirm,
    };
  },
});
