
import { computed, defineComponent, reactive, ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import dayjs from 'dayjs';

import { promoteQuery } from '@/apollo/queries';
import { formatDate, commafy } from '@/utils/formatter';
import { PromoteUserTypeEnum, PromoteUserTypeMapping } from '@/view-models/promote.vm';

import VanFieldPicker from '@/components/VanFieldPicker.vue';
import VanFieldDateRange from '@/components/VanFieldDateRange.vue';
import { typeChecker } from '@re/utilities';

export default defineComponent({
  components: {
    VanFieldPicker,
    VanFieldDateRange,
  },
  setup() {
    const inviteeTypeOptions = [
      { text: '全部', value: '' },
      { text: '消費者', value: PromoteUserTypeEnum.consumer },
      { text: '外送員', value: PromoteUserTypeEnum.deliveryDriver },
    ];
    const filter = reactive({
      inviteeType: '',
      dateRange: [
        dayjs()
          .startOf('month')
          .toDate(),
        dayjs()
          .endOf('day')
          .toDate(),
      ],
    });
    const pageInfo = reactive({
      index: 1,
      size: 10,
      amount: ref(0),
    });
    const isShowall = computed(() => typeChecker.isNullOrUndefinedOrWhiteSpace(filter.inviteeType));
    const isShowConsumer = computed(() => PromoteUserTypeEnum.consumer === filter.inviteeType);
    const isShowDeliveryDrive = computed(() => filter.inviteeType === PromoteUserTypeEnum.deliveryDriver);

    const { result, onResult } = useQuery<{ promoteStatusList: any }>(promoteQuery.getPromoteStatusList, () => ({
      param: {
        inviteeType: filter.inviteeType || null,
        dateStartInviteeRegistered: filter.dateRange[0],
        dateEndInviteeRegistered: filter.dateRange[1],
      },
      page: {
        index: pageInfo.index,
        size: pageInfo.size,
      },
    }));
    const promoteStatusList = computed(() => result.value?.promoteStatusList ?? []);
    onResult((ret) => {
      const { page } = ret.data.promoteStatusList;
      pageInfo.amount = page.dataAmount;
    });

    const { result: result2 } = useQuery<{ promoteStatusDashboard: any }>(
      promoteQuery.getPromoteStatusDashboard,
      () => ({
        param: {
          inviteeType: filter.inviteeType || null,
          dateStartInviteeRegistered: filter.dateRange[0],
          dateEndInviteeRegistered: filter.dateRange[1],
        },
      }),
    );
    const promoteStatusDashboard = computed(() => result2.value?.promoteStatusDashboard ?? null);

    function getPromoteUserType(type: string) {
      switch (type) {
        case PromoteUserTypeEnum.consumer:
          return 'primary';
        case PromoteUserTypeEnum.deliveryDriver:
          return 'success';
      }
    }
    function getFirstConsumedStatusColor(isInviteeFirstConsumed: boolean, isInviteeFirstConsumedEffected: boolean) {
      switch (true) {
        case isInviteeFirstConsumed && isInviteeFirstConsumedEffected:
          return 'text-green-500';
        case isInviteeFirstConsumed && !isInviteeFirstConsumedEffected:
          return 'text-yellow-500';
        default:
          return 'text-gray-500';
      }
    }

    return {
      PromoteUserTypeMapping,

      filter,
      pageInfo,
      inviteeTypeOptions,
      promoteStatusList,
      promoteStatusDashboard,
      isShowall,
      isShowConsumer,
      isShowDeliveryDrive,

      formatDate,
      commafy,
      getPromoteUserType,
      getFirstConsumedStatusColor,
    };
  },
});
